import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
import { graphql } from 'gatsby';
import Callout from '../../components/Callout';
import ComponentApi from '../../components/ComponentApi';
import ReactPlayground from '../../components/ReactPlayground';
import AdditionalContent from '../../examples/Alert/AdditionalContent';
import Basic from '../../examples/Alert/Basic';
import Dismissible from '../../examples/Alert/Dismissible';
import DismissibleControlled from '../../examples/Alert/DismissibleControlled';
import Link from '../../examples/Alert/Link';
export const query = graphql`
  query AlertQuery {
    Alert: componentMetadata(displayName: { eq: "Alert" }) {
      ...ComponentApi_metadata
    }
    AlertLink: componentMetadata(displayName: { eq: "AlertLink" }) {
      ...ComponentApi_metadata
    }
    AlertHeading: componentMetadata(displayName: { eq: "AlertHeading" }) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "alerts"
    }}>{`Alerts`}</h1>
    <p className="lead">
  Provide contextual feedback messages for typical user actions with the handful
  of available and flexible alert messages.
    </p>
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <p>{`Alerts are available for any length of text, as well as an optional
dismiss button. For proper styling, use one of the eight `}<inlineCode parentName="p">{`variant`}</inlineCode>{`s.`}</p>
    <ReactPlayground codeText={Basic} mdxType="ReactPlayground" />
    <Callout title="Conveying meaning to assistive technologies" mdxType="Callout">
  Using color to add meaning only provides a visual indication, which will not
  be conveyed to users of assistive technologies – such as screen readers.
  Ensure that information denoted by the color is either obvious from the content
  itself (e.g. the visible text), or is included through alternative means,
  such as additional text hidden with the <code>.sr-only</code> class.
    </Callout>
    <h3 {...{
      "id": "links"
    }}>{`Links`}</h3>
    <p>{`For links, use the `}<inlineCode parentName="p">{`<Alert.Link>`}</inlineCode>{` component to provide matching
colored links within any alert.`}</p>
    <ReactPlayground codeText={Link} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "additional-content"
    }}>{`Additional content`}</h3>
    <p>{`Alerts can contain whatever content you like. Headers, paragraphs, dividers, go crazy.`}</p>
    <ReactPlayground codeText={AdditionalContent} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "dismissing"
    }}>{`Dismissing`}</h3>
    <p>{`Add the `}<inlineCode parentName="p">{`dismissible`}</inlineCode>{` prop to add a functioning dismiss
button to the Alert.`}</p>
    <ReactPlayground codeText={Dismissible} mdxType="ReactPlayground" />
    <p>{`You can also control the visual state directly which is great if you
want to build more complicated alerts.`}</p>
    <ReactPlayground codeText={DismissibleControlled} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ComponentApi metadata={props.data.Alert} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.AlertHeading} exportedBy={props.data.Alert} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.AlertLink} exportedBy={props.data.Alert} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      